import img0 from './1.png';
import img1 from './2.png';
import img2 from './3.png';
import img3 from './4.png';
import img4 from './5.png';
import img5 from './6.png';
import img6 from './7.png';
import img7 from './8.png';
import img8 from './9.png';
import img9 from './10.png';
import img10 from './11.png';
import img11 from './12.png';
import img12 from './13.png';
import img13 from './14.png';
import img14 from './15.png';
import img15 from './16.png';
import img16 from './17.png';
import img17 from './18.png';
import img18 from './19.png';
import img19 from './20.png';
import img20 from './21.png';
import img21 from './22.png';
import img22 from './23.png';
import img23 from './24.png';
import img24 from './25.png';
import img25 from './26.png';
import img26 from './27.png';
import img27 from './28.png';
import img28 from './29.png';
import img29 from './30.png';
import img30 from './31.png';
import img31 from './32.png';
import img32 from './33.png';
import img33 from './34.png';
import img34 from './35.png';
import img35 from './36.png';
import img36 from './37.png';
import img37 from './38.png';
import img38 from './39.png';
import img39 from './40.png';
import img40 from './41.png';
import img41 from './42.png';
import img42 from './43.png';
import img43 from './44.png';
import img44 from './45.png';
import img45 from './46.png';
import img46 from './47.png';
import img47 from './48.png';
import img48 from './49.png';
import img49 from './50.png';
import img50 from './51.png';
import img51 from './52.png';
import img52 from './53.png';
import img53 from './54.png';
import img54 from './55.png';
import img55 from './56.png';
import img56 from './57.png';


export const landingTypingArr = [
    img0,
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
    img18,
    img19,
    img20,
    img21,
    img22,
    img23,
    img24,
    img24,
    img24,
    img24,
    img24,
    img24,
    img24,
    img24,
    img25,
    img26,
    img27,
    img28,
    img29,
    img30,
    img31,
    img32,
    img33,
    img34,
    img35,
    img36,
    img36,
    img36,
    img36,
    img36,
    img36,
    img36,
    img36,
    img36,
    img36,
    img37,
    img38,
    img39,
    img40,
    img41,
    img42,
    img43,
    img44,
    img45,
    img46,
    img47,
    img48,
    img49,
    img50,
    img51,
    img51,
    img51,
    img51,
    img51,
    img51,
    img51,
    img51,
    img52,
    img52,
    img52,
    img53,
    img53,
    img53,
    img53,
    img53,
    img53,
    img53,
    img54,
    img55,
    img56,
    img56,
    img56,
    img56,
    img56,
    img56,
    img56,
]
